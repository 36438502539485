
module.exports = config = {

    paymentMethods: {
        list: ['Standard'],
        hidden: true
    },

    listing: {
        surgeonOptional: true,
        surgeonHidden: true
    }

}