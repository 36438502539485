// https://purecss.io/

// MENU OVERLAY
// ------------------------------------------------------------------------------------
var mobileMenuIsOpen = false; /*20180709*/
const config = require('../config')
document.addEventListener('click', function (e) {
  if (e.target == document.getElementsByClassName('mobile-menu-icon')[0] && mobileMenuIsOpen == false) {
    // only open on menu icon click
    openMobileNavBar();
  } else {
    if (e.target !== document.getElementById('searchBar') && mobileMenuIsOpen == true) {
      // only close if menu is open, and the click is not on the search bar
      closeMobileNavBar();
    }
  }
});

window.openMobileNavBar = openMobileNavBar;
function openMobileNavBar() {
  mobileMenuIsOpen = true;
  document.getElementsByClassName('pure-menu-list')[0].classList.add('displayBlockMobile');
  setTimeout(function () {
    document.getElementsByClassName('pure-menu-list')[0].classList.add('opacity1Mobile');
    setTimeout(function () {
      //
    }, 100);
  }, 10);
}

window.closeMobileNavBar = closeMobileNavBar;
function closeMobileNavBar() {
  mobileMenuIsOpen = false;
  document.getElementsByClassName('pure-menu-list')[0].classList.remove('opacity1Mobile');
  setTimeout(function () {
    document.getElementsByClassName('pure-menu-list')[0].classList.remove('displayBlockMobile');
    setTimeout(function () {
      //
    }, 10);
  }, 300);
}

// DATE PICKER OPTIONS  https://chmln.github.io/flatpickr/
// ------------------------------------------------------------------------------------

// New listing (date time)
const flatpickr = require('flatpickr');
flatpickr('#newListingDateTime', {
  dateFormat: 'Y-m-d',
  altInput: true,
  altFormat: 'F j, Y',
  altInputClass: 'newListingDateTimeReadable',
});

// New overview
Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};
var startOverviewDefault = new Date();
startOverviewDefault.setDate(startOverviewDefault.getDate() + ((7 - startOverviewDefault.getDay()) % 7));
var endOverviewDefault = new Date();
endOverviewDefault = startOverviewDefault.addDays(6);

flatpickr('#overviewDateTime', {
  dateFormat: 'U',
  altInput: true,
  altFormat: 'J F',
  altInputClass: 'overviewDateTimeReadable',

  mode: 'range',
  inline: true,

  defaultDate: [startOverviewDefault, endOverviewDefault],
});

// MENU OVERLAY
// ------------------------------------------------------------------------------------

// Add the menu listing overlay when the button is clicked
document.getElementById('menuCreateNewListing').addEventListener('click', function (e) {
  openMenuCreateNewListingPage();
});

window.openMenuCreateNewListingPage = openMenuCreateNewListingPage;
function openMenuCreateNewListingPage() {
  document.getElementById('overlayNewListing').classList.remove('hidden');
  setTimeout(function () {
    document.getElementById('overlayNewListing').classList.remove('transition-opacity-0');
    setTimeout(function () {
      document.getElementById('overlayNewListingInner').classList.remove('transition-fadeInDown-0');
      if (config.listing.surgeonHidden) {
        let surgeonDiv = document.querySelector('#newListingVariable1ListContainer')
        surgeonDiv.parentNode.setAttribute('style', 'display:none')
      }

    }, 100);
  }, 10);
}
/*document.getElementById('overlayNewListing').addEventListener('click', function(e) {
	closeNewListingOverlay();
});*/
document.getElementById('overlayNewListingCancel').addEventListener('click', function (e) {
  closeNewListingOverlay();
});

window.closeNewListingOverlay = closeNewListingOverlay;
function closeNewListingOverlay() {
  document.getElementById('overlayNewListing').classList.add('transition-opacity-0');
  setTimeout(function () {
    document.getElementById('overlayNewListing').classList.add('hidden');
    document.getElementById('overlayNewListingInner').classList.add('transition-fadeInDown-0');
  }, 300);
}

// Close the detailed view to go back to list view
document.getElementById('mainContentMinimise').addEventListener('click', function (e) {
  mainViewOpen = false;
  document.getElementById('list').classList.add('list-full-width');
  //document.getElementsByClassName("main-item-selected")[0].classList.remove('main-item-selected');
  setTimeout(function () {
    if (user.uid == adminId) {
      document.getElementById('main-content-body-admin').classList.add('hidden');
      document.getElementById('main-content-body-admin').classList.add('transition-opacity-0');
    } else {
      document.getElementById('main-content-body-member').classList.add('hidden');
      document.getElementById('main-content-body-member').classList.add('transition-opacity-0');
    }
    document.getElementById('main-content-loader').classList.remove('hidden');
    document.getElementById('main-content-loader').classList.remove('transition-opacity-0');

    removeChildNodesFromId('main-content-subtitle');
    removeChildNodesFromId('main-response-list-allocated');
    removeChildNodesFromId('main-response-list-yes');
    removeChildNodesFromId('main-response-list-maybe');
    removeChildNodesFromId('main-response-list-no');
    removeChildNodesFromId('main-response-list-open');
  }, 300);
}); /*document.getElementById('menuNotifications').addEventListener('click', function(e) {
	document.getElementById('overlayNotification').classList.remove("hidden");
	setTimeout(function(){
		document.getElementById('overlayNotification').classList.remove("transition-opacity-0");
			setTimeout(function(){
			document.getElementById('overlayNotificationInner').classList.remove("transition-fadeInDown-0");
		}, 100);
	}, 10);
});
document.getElementById('overlayNotificationEnableCancel').addEventListener('click', function(e) {
	closeNotificationOverlay();
});
document.getElementById('overlayNotificationSettingsCancel').addEventListener('click', function(e) {
	closeNotificationOverlay();
});
function closeNotificationOverlay() {
	document.getElementById('overlayNotification').classList.add("transition-opacity-0");
	setTimeout(function(){
		document.getElementById('overlayNotification').classList.add("hidden");
		document.getElementById('overlayNotificationInner').classList.add("transition-fadeInDown-0");
	}, 300);
}*/ // Close the detailed view to go back to list view

// NOTIFICATIONS SETTING MENU
// ------------------------------------------------------------------------------------

// Add the menu listing overlay when the button is clicked
/*#NTH*/ document.getElementById('mainContentMinimise').addEventListener('click', function (e) {
  mainViewOpen = false;
  document.getElementById('list').classList.add('list-full-width');
  document.getElementsByClassName('main-item-selected')[0].classList.remove('main-item-selected');
  setTimeout(function () {
    if (user.uid == adminId) {
      document.getElementById('main-content-body-admin').classList.add('hidden');
      document.getElementById('main-content-body-admin').classList.add('transition-opacity-0');
    } else {
      document.getElementById('main-content-body-member').classList.add('hidden');
      document.getElementById('main-content-body-member').classList.add('transition-opacity-0');
    }
    document.getElementById('main-content-loader').classList.remove('hidden');
    document.getElementById('main-content-loader').classList.remove('transition-opacity-0');

    removeChildNodesFromId('main-response-list-allocated');
    removeChildNodesFromId('main-response-list-yes');
    removeChildNodesFromId('main-response-list-maybe');
    removeChildNodesFromId('main-response-list-no');
    removeChildNodesFromId('main-response-list-open');
  }, 300);
});

// OVERVIEW OVERLAY
// ------------------------------------------------------------------------------------

// Add the menu listing overlay when the button is clicked
document.getElementById('menuOverview').addEventListener('click', function (e) {
  document.getElementById('overlayOverview').classList.remove('hidden');
  setTimeout(function () {
    document.getElementById('overlayOverview').classList.remove('transition-opacity-0');
    setTimeout(function () {
      document.getElementById('overlayOverviewInner').classList.remove('transition-fadeInDown-0');
    }, 100);
  }, 10);
});
/*document.getElementById('overlayOverview').addEventListener('click', function(e) {
	closeOverviewOverlay();
});*/

document.getElementById('overlayOverviewCancel').addEventListener('click', function (e) {
  closeOverviewOverlay();
});
document.getElementById('overlayOverviewContentCancel').addEventListener('click', function (e) {
  closeOverviewOverlay();
});

window.closeOverviewOverlay = closeOverviewOverlay;
function closeOverviewOverlay() {
  document.getElementById('overlayOverview').classList.add('transition-opacity-0');
  setTimeout(function () {
    document.getElementById('overlayOverview').classList.add('hidden');
    document.getElementById('overlayOverviewInner').classList.add('transition-fadeInDown-0');
    setTimeout(function () {
      resetOverviewReport();
    }, 300);
  }, 300);
}

window.resetOverviewReport = resetOverviewReport;
function resetOverviewReport() {
  removeChildNodesFromId('overlayOverviewContent');
  document.getElementById('overlayOverviewInner').classList.add('overlayOverviewInnerResize');
  document.getElementById('overlayOverviewContentContainer').classList.add('transition-opacity-0');
  setTimeout(function () {
    document.getElementById('overlayOverviewContentContainer').classList.add('hidden');
    document.getElementById('overlayOverviewFormContainer').classList.remove('transition-opacity-0');
    setTimeout(function () {
      document.getElementById('overlayOverviewFormContainer').classList.remove('hidden');
    }, 300);
  }, 300);
}

// EXCLUSION DATES OVERLAY
// ------------------------------------------------------------------------------------

// Add the menu listing overlay when the button is clicked
document.getElementById('mainContentAddExclusionDates').addEventListener('click', function (e) {
  document.getElementById('overlayExclusionDates').classList.remove('hidden');
  loadFromFirebasePath('/listings/' + currentDetailViewId)
    .then(function (obj) {
      var exclusionDatesLoadArray = obj.exclusionDates;

      flatpickr('#exclusionDatesDateTime', {
        plugins: [],
        dateFormat: 'U',
        altInput: true,
        altFormat: 'j M',
        altInputClass: 'exclusionDatesDateTimeReadable',
        mode: 'multiple',
        inline: true,
        defaultDate: exclusionDatesLoadArray,
      });
    })
    .then(function () {
      setTimeout(function () {
        document.getElementById('overlayExclusionDates').classList.remove('transition-opacity-0');
        setTimeout(function () {
          document.getElementById('overlayExclusionDatesInner').classList.remove('transition-fadeInDown-0');
        }, 100);
      }, 10);
    });
});

document.getElementById('overlayExclusionDatesCancel').addEventListener('click', function (e) {
  closeExclusionDatesOverlay();
});

window.closeExclusionDatesOverlay = closeExclusionDatesOverlay;
function closeExclusionDatesOverlay() {
  document.getElementById('overlayExclusionDates').classList.add('transition-opacity-0');
  setTimeout(function () {
    document.getElementById('overlayExclusionDates').classList.add('hidden');
    document.getElementById('overlayExclusionDatesInner').classList.add('transition-fadeInDown-0');
    document.getElementById('overlayExclusionDatesFormContainer').classList.remove('hidden');
    setTimeout(function () {
      document.getElementById('overlayExclusionDatesFormContainer').classList.remove('transition-opacity-0');
    }, 10);
  }, 300);
}

// RECURRING END DATE OVERLAY
// ------------------------------------------------------------------------------------

// Add the menu listing overlay when the button is clicked
document.getElementById('mainContentAddEndDate').addEventListener('click', function (e) {
  document.getElementById('overlayEndDate').classList.remove('hidden');
  loadFromFirebasePath('/listings/' + currentDetailViewId)
    .then(function (obj) {
      var endDateLoad = obj.recurringEndDate;

      var endDatePickr = flatpickr('#endDateDateTime', {
        plugins: [],
        dateFormat: 'U',
        altInput: true,
        altFormat: 'l J F',
        altInputClass: 'endDateDateTimeReadable',
        mode: 'single',
        inline: true,
        defaultDate: endDateLoad,
      });
    })
    .then(function () {
      setTimeout(function () {
        document.getElementById('overlayEndDate').classList.remove('transition-opacity-0');
        setTimeout(function () {
          document.getElementById('overlayEndDateInner').classList.remove('transition-fadeInDown-0');
        }, 100);
      }, 10);
    });
});

document.getElementById('endDateReportRemove').addEventListener('click', function (e) {
  removeCurrentEndDateOverlay();
});

window.removeCurrentEndDateOverlay = removeCurrentEndDateOverlay;
function removeCurrentEndDateOverlay() {
  var endDatePickr = flatpickr('#endDateDateTime', {
    plugins: [],
    dateFormat: 'U',
    altInput: true,
    altFormat: 'l J F',
    altInputClass: 'endDateDateTimeReadable',
    mode: 'single',
    inline: true,
    defaultDate: '',
  });
}

document.getElementById('overlayEndDateCancel').addEventListener('click', function (e) {
  closeEndDateOverlay();
});

window.closeEndDateOverlay = closeEndDateOverlay;
function closeEndDateOverlay() {
  document.getElementById('overlayEndDate').classList.add('transition-opacity-0');
  setTimeout(function () {
    document.getElementById('overlayEndDate').classList.add('hidden');
    document.getElementById('overlayEndDateInner').classList.add('transition-fadeInDown-0');
    document.getElementById('overlayEndDateFormContainer').classList.remove('hidden');
    setTimeout(function () {
      document.getElementById('overlayEndDateFormContainer').classList.remove('transition-opacity-0');
    }, 10);
  }, 300);
}

// Close the detailed view to go back to list view
document.getElementById('mainContentMinimise').addEventListener('click', function (e) {
  minimiseMainContent();
});

window.minimiseMainContent = minimiseMainContent;
function minimiseMainContent() {
  mainViewOpen = false;
  updateUrlToCurrentDetailedView();
  document.getElementById('list').classList.add('list-full-width');
  //document.getElementsByClassName("main-item-selected")[0].classList.remove('main-item-selected');
  setTimeout(function () {
    if (user.uid == adminId) {
      document.getElementById('main-content-body-admin').classList.add('hidden');
      document.getElementById('main-content-body-admin').classList.add('transition-opacity-0');
    } else {
      document.getElementById('main-content-body-member').classList.add('hidden');
      document.getElementById('main-content-body-member').classList.add('transition-opacity-0');
    }
    document.getElementById('main-content-loader').classList.remove('hidden');
    document.getElementById('main-content-loader').classList.remove('transition-opacity-0');
    document.getElementById('main-response-member-select-form').classList.add('transition-opacity-0');
    document.getElementById('main-response-member-select-form').classList.add('hidden');

    removeChildNodesFromId('main-response-list-allocated');
    removeChildNodesFromId('main-response-list-yes');
    removeChildNodesFromId('main-response-list-maybe');
    removeChildNodesFromId('main-response-list-no');
    removeChildNodesFromId('main-response-list-open');

    // For members reset submit button
    document.getElementById('main-response-member-option-yes').classList.remove('main-response-member-option-inactive');
    document
      .getElementById('main-response-member-option-maybe')
      .classList.remove('main-response-member-option-inactive');
    document.getElementById('main-response-member-option-no').classList.remove('main-response-member-option-inactive');
    submitResponseButtonVal = '';
    document.getElementById('main-response-attach-note').value = '';
  }, 300);
}

// Some simple functions to toggle between compact and non-compact mode. Only for admin at the moment
var aiCompactHasBeenChecked = false;
// Toggle Large
document.getElementsByClassName('toggle-large')[0].addEventListener('click', function (e) {
  layoutEnableNonCompactMode();
});

window.layoutEnableNonCompactMode = layoutEnableNonCompactMode;
function layoutEnableNonCompactMode() {
  // Toggle the html class
  document.documentElement.classList.remove('compact-view');
  // Toggle the side icons
  document.getElementsByClassName('toggle-compact')[0].classList.remove('hidden');
  document.getElementsByClassName('toggle-large')[0].classList.add('hidden');
  // Check if it's being toggled after the first load and save preferences to db
  if (aiCompactHasBeenChecked == false) {
    aiCompactHasBeenChecked = true;
  } else {
    saveToFirebasePath('/settings/', {
      adminIsCompactMode: false,
    }).then(function (obj) {
      console.log('compact mode settings saved');
    });
  }
  // Aasy fix for lists being cut off when compact view is disabled is to toggle the resize functions
  expandPanelList('open', 'up');
  expandPanelList('open', 'down');
  expandPanelList('allocated', 'up');
  expandPanelList('allocated', 'down');
  expandPanelList('recurring', 'up');
  expandPanelList('recurring', 'down');
  expandPanelList('closed', 'up');
  expandPanelList('closed', 'down');
}

// Toggle Compact
document.getElementsByClassName('toggle-compact')[0].addEventListener('click', function (e) {
  layoutEnableCompactMode();
});
window.layoutEnableCompactMode = layoutEnableCompactMode;
function layoutEnableCompactMode() {
  // toggle the html class
  document.documentElement.classList.add('compact-view');
  // Toggle the side icons
  document.getElementsByClassName('toggle-compact')[0].classList.add('hidden');
  document.getElementsByClassName('toggle-large')[0].classList.remove('hidden');
  // Check if it's being toggled after the first load and save preferences to db
  if (aiCompactHasBeenChecked == false) {
    aiCompactHasBeenChecked = true;
  } else {
    saveToFirebasePath('/settings/', {
      adminIsCompactMode: true,
    }).then(function (obj) {
      console.log('compact mode settings saved');
    });
  }
}

// LOAD VARIABLE NAMES
// ------------------------------------------------------------------------------------
window.layoutJsAfterFirebase = layoutJsAfterFirebase;
function layoutJsAfterFirebase() {
  loadFromFirebasePath('/settings/').then(function (obj) {
    updateVariableNames(obj.variableNames);
    storeContactsOnInitialLoad();
    if (user.uid == adminId) {
      if (obj.adminIsCompactMode == true) {
        layoutEnableCompactMode();
      } else {
        layoutEnableNonCompactMode();
      }
    }
  });
  //Prepare the notifications settings menu
  if (user.uid == adminId) {
    // Set name of sorting to Unread if they're an admin
    setPersonalisationName({
      spanName: 'ai-sort-unread',
      variableName: 'Unread',
      capitalisation: 'firstletter',
    });
    // Don't load notifications for admin in testing
  } else {
    // Set name of sorting to Response if they're a member
    setPersonalisationName({
      spanName: 'ai-sort-unread',
      variableName: 'Response',
      capitalisation: 'firstletter',
    });
    loadFromFirebasePath('/contacts/' + user.uid + '/notificationInfo').then(function (obj) {
      //console.log(obj);
      setPersonalisationName({
        spanName: 'ai-active-push-device',
        variableName: obj.activePushDevice,
      });
      /*#NTH*/ //document.getElementById('notificationSettingEmail').checked = obj.emailSubscription;
      //document.getElementById('notificationSettingPush').checked = obj.pushSubscription;
    });
  }
}

window.removeChildNodesFromId = removeChildNodesFromId;
function removeChildNodesFromId(el) {
  var node = document.getElementById(el);
  while (node.hasChildNodes()) {
    node.removeChild(node.firstChild);
  }
}

window.setPersonalisationName = setPersonalisationName;
function setPersonalisationName(input) {
  var spanName = input.spanName;
  var variableName = input.variableName;
  var variableCapitalisation = input.capitalisation;
  if (variableCapitalisation == 'lowercase') {
    //variableName = variableName.toLowerCase();
  } else {
    if (variableCapitalisation == 'uppercase') {
      //variableName = variableName.toUpperCase();
    } else {
      if (variableCapitalisation == 'firstletter') {
        //variableName = variableName.charAt(0).toUpperCase() + variableName.slice(1);
      } else {
        if (variableCapitalisation == 'none' || variableCapitalisation == undefined) {
          //
        }
      }
    }
  }
  var x = document.getElementsByName(spanName);
  for (var i = 0; i < x.length; i++) {
    x[i].innerHTML = variableName;
  }
}
var variable3GlobalName;
window.updateVariableNames = updateVariableNames;
function updateVariableNames(obj) {
  setPersonalisationName({
    spanName: 'ai-contact', // <span name="ai-contact"></span>
    variableName: obj.contact, // innerHTML
    capitalisation: 'firstletter', // 'lowercase', 'uppercase', 'firstletter', 'none'
  });
  setPersonalisationName({
    spanName: 'ai-contact-lowercase',
    variableName: obj.contact,
    capitalisation: 'lowercase',
  });
  setPersonalisationName({
    spanName: 'ai-location',
    variableName: obj.location,
    capitalisation: 'firstletter',
  });
  setPersonalisationName({
    spanName: 'ai-location-lowercase',
    variableName: obj.location,
    capitalisation: 'lowercase',
  });
  setPersonalisationName({
    spanName: 'ai-notes',
    variableName: obj.notes,
    capitalisation: 'firstletter',
  });
  setPersonalisationName({
    spanName: 'ai-recipients',
    variableName: obj.recipients,
    capitalisation: 'firstletter',
  });
  setPersonalisationName({
    spanName: 'ai-recipients-lowercase',
    variableName: obj.recipients,
    capitalisation: 'lowercase',
  });
  setPersonalisationName({
    spanName: 'ai-variable1',
    variableName: obj.variable1 || '',
    capitalisation: 'firstletter',
  });
  setPersonalisationName({
    spanName: 'ai-variable1-lowercase',
    variableName: obj.variable1 || '',
    capitalisation: 'lowercase',
  });

  updateLoadingSegment(1);
  splashScreenUserIsAdmin(); //*****
  splashScreenUserIsMember(); //*****
  splashScreenUserIsHospital();
}

// FADE OUT SPLASH SCREEN
// ------------------------------------------------------------------------------------
window.removeSplashScreen = removeSplashScreen;
function removeSplashScreen() {
  document.getElementById('allocateItSplashScreen').classList.add('transition-opacity-0');
  setTimeout(function () {
    document.getElementById('allocateItSplashScreen').classList.add('hidden');
  }, 300);
}

// FADE OUT LOGINSCREEN
// ------------------------------------------------------------------------------------
window.removeLoginScreen = removeLoginScreen;
function removeLoginScreen() {
  document.getElementById('allocateItLoginScreen').classList.add('transition-opacity-0');
  setTimeout(function () {
    document.getElementById('allocateItLoginScreen').classList.add('hidden');
  }, 300);
}
